<template>
  <loading :show="isLoading">
    <b-card>
      <b-table
          ref="table"
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          hover
          responsive
          show-empty
          :empty-text="$i18n.t('table.no_data')"
      >
        <template #cell(value)="data">
          <b-form-input
              :key="data.item.name"
              v-model="data.item.value"
          />
        </template>

        <template #cell(group)="data">
          <v-select
              :key="data.item.name"
              v-model="data.item.group"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groups"
              :reduce="val => val.id"
              label="name"
              :clearable="false"
              append-to-body
              class="w-100"
          />
        </template>
      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          class="my-0 ml-auto"
      />
      <b-button
          type="submit"
          variant="primary"
          class="float-md-right m-1"
          @click="save()"
      >
        {{ $i18n.t('save') }}
      </b-button>
    </b-card>
  </loading>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      items: [],
      isLoading: false,
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      groups: [],
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$i18n.t('settings.platform-mapping.fields.key'),
          sortable: true
        },
        {
          key: 'value',
          label: this.$i18n.t('settings.platform-mapping.fields.value'),
          sortable: false
        },
        {
          key: 'group',
          label: this.$i18n.t('settings.platform-mapping.fields.group'),
          sortable: false
        },
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      let res = await this.$http.get('utils/platform-groups')
      this.groups = res.data.data.groups
      res = await this.$http.get('settings/platform-mapping')
      this.items = res.data.data
      this.isLoading = false
    },
    save() {
      if (!this.isLoading) {
        this.isLoading = true
        this.$http.post('/settings/platform-mapping', {data: this.items}).then(response => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('Success'),
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message
            },
          })
        }).catch(error => {
          this.isLoading = false
          if (error.data && error.data.errors) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'TrashIcon',
                variant: 'danger',
                text: error.data.message,
              },
            })
          }
        })
      }
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
